













































import { Vue, Component, Prop } from "vue-property-decorator";

import {
	Button,
	Icon
} from "view-design";

@Component({
	components: {
		Button,
		Icon
	}
})
export default class Message extends Vue {
	@Prop() public type!: "image" | "text" | "option" | "suggestion" | "user";
	@Prop() public message: any;
}
