import { UAParser } from "ua-parser-js";

export default function healthCheck() {

	console.log("Starting health check");
	// @ts-ignore
	window.lastHeartBeat = new Date().getTime();

	const errors: string[] = [];
	const fatals: string[] = [];
	let data: any = {
		screen: {
		}
	};
	const debug: string[] = [];

	// Health check before running

	// Detect browser
	try {
		const parser = new UAParser();
		data.browser = parser.getResult();
	} catch (e) {
		console.error(e);
		errors.push("useragent");
		debug.push(e.message);
	}
	// Detect features
	try {
		data.hasMatchMedia = typeof window.matchMedia !== "undefined";
		data.hasWindowScreen = typeof window.screen !== "undefined";

		if (data.hasMatchMedia) {
			const queryTests = {
				hasTouch: "(hover: none)",
				canHover: "(hover: hover)",
				hasFinePointer: "(pointer: fine)",
				hasCoarsePointer: "(pointer: coarse)",
				prefersLight: "(prefers-color-scheme: light)",
				prefersDark: "(prefers-color-scheme: dark)",
				noLightDarkPreference: "(prefers-color-scheme: no-preference)",
				isPortrait: "(orientation: portrait)",
				isLandscape: "(orientation: landscape)",
				noPointer: "(pointer: none)",
			};

			(Object.entries(queryTests) as [string, any]).forEach(
				([test, query]) => data[test] = window.matchMedia(query).matches
			);
		}


		["height", "width", "availHeight", "availWidth", "availTop", "availLeft", "colorDepth", "pixelDepth"]
			// @ts-ignore
			.forEach((key: string) => data[key] = window.screen[key]);
		if (typeof window.screen.orientation === "object") {
			data.screen.orientation = {};

			["angle", "onchange", "type"]
				// @ts-ignore
				.forEach((key: string) => data.screen.orientation[key] = window.screen.orientation[key]);
		}
		// Detect required features
		data.navigator = {};

		["languages", "language", "hardwareConcurrency", "doNotTrack", "deviceMemory", "cookieEnabled", "maxTouchPoints"]
			// @ts-ignore
			.forEach((key: string) => data.navigator[key] = navigator[key]);

	} catch (e) {
		console.error(e);
		errors.push("browser");
		debug.push(e.message);
	}

	// Detect network connectivity

	try {
		if (window.navigator.onLine === false) {
			fatals.push("online");
		}
		data.onLine = window.navigator.onLine;
		// @ts-ignore
		const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
		data.connectionType = connection.effectiveType;
		data.downlink = connection.downlink;
		data.rtt = connection.rtt;
	} catch (e) {
		console.error(e);
		errors.push("online");
		debug.push(e.message);
	}

	// Detect internet connectivity

	fetch("https://neuvola.com", {
		method: "GET", // *GET, POST, PUT, DELETE, etc.
		mode: "no-cors", // no-cors, *cors, same-origin
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		credentials: "same-origin", // include, *same-origin, omit
		redirect: "follow", // manual, *follow, error
	}).then(response => {
		response.text();
	}).catch(e => {
		console.error(e);
		fatals.push("internet");
		debug.push(e.message);
	});

	console.log(data);

	// Detect server connectivity via anonylytics
	if (window.location.pathname === "/espoo") {
		data = "anon";
	}
	fetch(process.env.VUE_APP_CHAT_API + "/anonylytics/add", {
		method: "POST",
		headers: {
			"content-type": "application/json"
		},
		body: JSON.stringify({
			location: window.location.pathname.replace("/", ""),
			type: "browser",
			data
		})
	}).then(response => {
		response.json();
	}).catch(e => {
		console.error(e);
		fatals.push("server");
		debug.push(e.message);
	});


	if (errors.length > 0 || fatals.length > 0) {
		fetch(process.env.VUE_APP_CHAT_API + "/anonylytics/add", {
			method: "POST",
			headers: {
				"content-type": "application/json"
			},
			body: JSON.stringify({
				location: window.location.pathname.replace("/", ""),
				type: "error",
				data: {
					errors,
					fatals,
					debug
				}
			})
		}).then(response => {
			response.json();
		}).catch(e => {
			console.error(e);
			fatals.push("server");
			debug.push(e.message);
		});
	}

	// @ts-ignore
	window.lastHeartBeat = new Date().getTime();

	if (fatals.length > 0) {
		alert("There was an error in running this program. Please try again or try opening " + window.location.href + " in another web browser or device.");
	}

}

