<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "app"
};
</script>

<style lang="less">
@import "~view-design/src/styles/index.less";

// Here are the variables to cover, such as:
@primary-color: #25638a;
@bg-color: #eceff3;

:root {
	--primary-color: @primary-color;
	--bg-color: @bg-color;
}

body,
html {
	min-height: 100%;
	min-height: 100vh;
}

html {
	background: @bg-color;
	scroll-behavior: smooth;
}

body {
	background: #f9fbfd;
	background: linear-gradient(0deg, #f9fbfd, @bg-color);
	background-attachment: fixed;
}

#app {
	font-family: "Avenir", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #102c3d;
	height: 100%;
	padding: 20px;
}

html body *:focus {
	outline: 0;
	box-shadow: 0px 0px 0px 5px #092945 !important;
	box-shadow: 0px 0px 0px 2px #FFFFFFDD, 0px 0px 0px 4px #092945EE !important;
}
html body .fab > *:focus {
	border-radius: 100%;
}
html body .bot a:focus {
	border-radius: 3px;
}

html body .ivu-dropdown-menu {
	max-height: calc(100vh - 30px) !important;
	overflow-y: auto !important;
}

</style>
