





































































































































































































import * as config from "../config.json";
import { Component, Vue, Watch } from "vue-property-decorator";
import Message from "@/components/Message.vue";
// @ts-ignore
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

import {
	Button,
	Input,
	Modal,
	Spin,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Icon,
	Select,
	Option,
	Row,
	Col,
	Poptip,
	Notice,
	Message as iMessage,
	Rate,
	Checkbox
} from "view-design";

@Component({
	components: {
		Button,
		Input,
		Dropdown,
		DropdownMenu,
		DropdownItem,
		Icon,
		Select,
		Option,
		Row,
		Col,
		Poptip,
		"vue-hcaptcha": VueHcaptcha,
		Message,
		Notice,
		Rate,
		Checkbox
	}
})
export default class Chat extends Vue {
	public conversation: any[] = [];
	public typing = false;
	public sessionId = "";
	public assistantId = "";
	public question = "";
	public currentLanguage = "";
	public config: any = {};
	public inited = false;
	public location = "neuvo";
	public sessionTimeout = 0;
	public captcha = false;
	public captchaToken = "";
	public hack = {
		"palvelupiste": "asiointipiste",
		"palvelupisteet": "asiointipisteet",
		"palvelupisteen": "asiointipisteen",
		"palvelupisteiden": "asiointipisteiden",
		"palvelupisteitten": "asiointipisteitten",
		"palvelupistettä": "asiointipistettä",
		"palvelupisteitä": "asiointipisteitä",
		"palvelupisteessä": "asiointipisteessä",
		"palvelupisteissä": "asiointipisteissä",
		"palvelupisteestä": "asiointipisteestä",
		"palvelupisteistä": "asiointipisteistä",
		"palvelupisteeseen": "asiointipisteeseen",
		"palvelupisteisiin": "asiointipisteisiin",
		"palvelupisteellä": "asiointipisteellä",
		"palvelupisteillä": "asiointipisteillä",
		"palvelupisteeltä": "asiointipisteeltä",
		"palvelupisteiltä": "asiointipisteiltä",
		"palvelupisteelle": "asiointipisteelle",
		"palvelupisteille": "asiointipisteille",
		"palvelupisteenä": "asiointipisteenä",
		"palvelupisteinä": "asiointipisteinä",
		"palvelupisteeksi": "asiointipisteeksi",
		"palvelupisteiksi": "asiointipisteiksi",
		"palvelupisteettä": "asiointipisteettä",
		"palvelupisteittä": "asiointipisteittä",
		"palvelupistein": "asiointipistein",
		"staden Espoo": "staden Esbo",
		"Espoo-invånare": "Esbo-invånare",
		"Espoo-tjänster": "Esbo-tjänster",
		"perustulotukea": "perustoimeentulotukea",
		"perustulotukia": "perustoimeentulotukea",
		"lisä- ja ennaltaehkäisevää tulotukia": "täydentävää ja ehkäisevää toimeentulotukea",
		"lisä- tai ennaltaehkäisevän tulotuen": "täydentävän ja ehkäisevän toimeentulotuen",
		"kasvonaamareiden": "kasvomaskien",
		"kasvonaamijoista": "kasvomaskeista",
		"kasvonaamareista": "kasvomaskeista",
		"kasvonaamareihin": "kasvomaskeihin",
		"kasvonaamareilla": "kasvomaskeilla",
		"kasvonaamareilta": "kasvomaskeilta",
		"kasvonaamareille": "kasvomaskeille",
		"kasvonaamareiksi": "kasvomaskeiksi",
		"kasvonaamareitta": "kasvomaskeitta",
		"kasvonaamareina": "kasvomaskeina",
		"kasvonaamioita": "kasvomaskeja",
		"kasvonaamareita": "kasvomaskeja",
		"kasvonaamioissa": "kasvomaskeissa",
		"kasvonaamarien": "kasvomaskien",
		"kasvonaamiossa": "kasvomaskissa",
		"kasvonaamiolta": "kasvomaskilta",
		"kasvonaamiolla": "kasvomaskilla",
		"kasvonaamiotta": "kasvomaskitta",
		"kasvonaamiolle": "kasvomaskille",
		"kasvonaamioksi": "kasvomaskiksi",
		"kasvonaamiosta": "kasvomaskista",
		"kasvonaamioon": "kasvomaskiin",
		"kasvonaamioin": "kasvomaskein",
		"kasvonaamarit": "kasvomaskit",
		"kasvonaamarin": "kasvomaskin",
		"kasvonaamiona": "kasvomaskina",
		"kasvonaamiota": "kasvomaskia",
		"kasvonaamion": "kasvomaskin",
		"kasvonaamio": "kasvomaski"
	};
	public help = {
		language: true
	};
	public languages = [
		{
			code: "en",
			name: "English"
		}
	];
	public topLanguages = [
		{
			code: "en",
			name: "English"
		}
	];
	public API = process.env.VUE_APP_CHAT_API;
	public version = process.env.VUE_APP_VERSION;
	// private lastScrollIndex = -2;
	// private scrollBlock = false;
	public scrolledToBottom = true;
	public refreshing: boolean = false;
	public registration: any = undefined;
	// @ts-ignore
	public SpeechRecognition: any = window.SpeechRecognition || window.webkitSpeechRecognition || false;
	public dictation: any = false;
	public dictating = false;

	private regexHack: any = [];
	private translationCache: any = {};
	private censors: Array<{ regex: RegExp, action: string }> = [];

	private feedbackUIVisible: boolean = false;

	public beforeCreate() {
		if (
			typeof this.$route.params.location === "string" &&
			// @ts-ignore
			typeof config.locations[this.$route.params.location.toLowerCase()] ===
			"undefined"
		) {
			this.$router.replace("/404");
		}
	}

	public async created() {
		const self = this;
		// @ts-ignore
		window.lastHeartBeat = true;

		// @ts-ignore
		Notice.config({
			duration: 0,
			top: 70
		});
		document.addEventListener("swUpdated", this.showRefreshUI, { once: true });

		if (typeof navigator.serviceWorker === "object") {
			try {
				navigator.serviceWorker.addEventListener("controllerchange", () => {
					if (this.refreshing) {
						return;
					}
					this.refreshing = true;
					window.location.assign(window.location.href);
				});
			} catch (e) {
				console.error(e);
			}
		}

		config.censors.forEach(c => {
			this.censors.push({
				regex: new RegExp(c.regex, "ig"),
				action: c.action
			});
		});

		for (const [from, to] of Object.entries(this.hack)) {
			const regex = new RegExp(from.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\u002d"), "gi");
			this.regexHack.push([regex, to]);
		}

		if (
			typeof this.$route.params.location === "string" &&
			// @ts-ignore
			typeof config.locations[this.$route.params.location.toLowerCase()] !==
			"undefined"
		) {
			this.location = this.$route.params.location.toLowerCase();
		}

		let defaultConfig: any = {};
		const currentConfig: any = config.domains.find((domain: any) => {
			if (typeof domain.default === "boolean" && domain.default === true) {
				defaultConfig = domain;
			}
			return domain.hostnames.includes(window.location.hostname);
		});
		if (typeof currentConfig !== "undefined") {
			this.config = currentConfig;
		} else {
			this.config = defaultConfig;
			console.log("Falling back to default config");
		}

		// @ts-ignore
		if (this.location !== ""
			// @ts-ignore
			&& typeof config.locations[this.location] === "object") {
			// @ts-ignore
			this.config = { ...this.config, ...config.locations[this.location] };
		}

		// At the lowest priority, we configure the default language set in the config
		this.currentLanguage = this.config.languages.default;

		// We try to autodetect the language
		await this.getLanguages();

		try {
			const langCodes = this.languages.map(lang => lang.code); // Get the language codes for each supported language
			// First we test the default selected navigator.language
			if (langCodes.includes(navigator.language)) {
				this.currentLanguage = navigator.language;
				console.log({ cur: this.currentLanguage });
			} else if (Array.isArray(navigator.languages)) {
				// If there was no match, we try the first match from navigator.languages
				for (const lang of navigator.languages) {
					// First we try with the full match
					const navLang = lang.replace("_", "-"); // Make sure we use the standard format
					const navLangLocale = navLang.replace(/-.+/, ""); // Remove country
					console.log({ navLang, navLangLocale });
					if (langCodes.includes(navLang)) { // Is the full language in the list?
						this.currentLanguage = navLang;
						break;
					} else if (langCodes.includes(navLangLocale)) {
						// We don't have an exact match, but do we have a non geographical match?
						this.currentLanguage = navLangLocale;
						break;
					}
				}
				console.log({ match: this.currentLanguage });
			}
		} catch (e) {
			console.error("Failed to handle lang autodetection", e);
		}

		// If the language is defined as the URL param, this takes the lead
		if (typeof this.$route.query.language === "string") {
			this.currentLanguage = this.$route.query.language;
		}

		if (typeof this.config === "object" && typeof this.config.assistantId === "string") {
			this.assistantId = this.config.assistantId;
		}
		document.documentElement.lang = this.currentLanguage;

		let dynamicManifest = {
			name: "Neuvo",
			short_name: "Neuvo",
			icons: [
				{
					src: `${window.location.origin}/img/icons/icon-72x72.png`,
					sizes: "72x72",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-96x96.png`,
					sizes: "96x96",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-128x128.png`,
					sizes: "128x128",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-144x144.png`,
					sizes: "144x144",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-152x152.png`,
					sizes: "152x152",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-192x192.png`,
					sizes: "192x192",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-384x384.png`,
					sizes: "384x384",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-512x512.png`,
					sizes: "512x512",
					type: "image/png"
				}
			],
			display: "standalone",
			background_color: "#e6eaef",
			theme_color: "#25638A",
			start_url: window.location.href
		};
		console.log(this.config);
		if (typeof this.config === "object" && typeof this.config.manifest === "object") {
			dynamicManifest = { ...dynamicManifest, ...this.config.manifest };
			if (typeof this.config.manifest.name === "string") {
				document.title = this.config.manifest.name;
			}
		}

		const stringManifest = JSON.stringify(dynamicManifest);
		const blob = new Blob([stringManifest], { type: "application/json" });
		const manifestURL = URL.createObjectURL(blob);
		document.querySelector("#manifest")!.setAttribute("href", manifestURL);


		setTimeout(() => {
			self.help.language = false;
		}, 20000);

		this.init();
	}

	public async init() {
		const self = this;
		// @ts-ignore
		Spin.show();
		fetch(`${this.API}/chat/session/${self.assistantId}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(res => res.json())
			.then(async data => {
				if (typeof data.session_id !== "undefined") {
					this.sessionId = data.session_id;
				} else {
					throw new Error(
						this._("Server couldn't start a chat session for you. Please Please try again later.")
					);
				}
				await this.ask("");
				self.inited = true;
			})
			.finally(() => {
				// @ts-ignore
				Spin.hide();
			})
			.catch(e => {
				// @ts-ignore
				Modal.error({
					title: this._("Error"),
					content: this._("Error with chat.") + " " + e
				});
			});

		if (this.SpeechRecognition !== false && typeof this.config.speech === "object") {
			console.log("Speech recognition supported");


			this.dictation = new this.SpeechRecognition();
			this.dictation.lang = this.currentLanguage;
			this.dictation.interimResults = true;
			this.dictation.addEventListener("result", (event: any) => {
				console.log({ event });
				const text = Array.from(event.results)
					.map((result: any) => result[0])
					.map(result => result.transcript)
					.join("");
				this.question = text;
			});
			this.dictation.addEventListener("start", () => {
				this.dictating = true;
			});
			this.dictation.addEventListener("end", () => {
				if (this.question !== "") {
					console.log("we got it", this.question);
					this.dictation.stop();
				}
				console.log("end event");
				this.dictating = false;
				this.ask(this.question, undefined, true);
			});

		} else {
			console.warn("Speech recognition is not supported on your \"browser\"");
		}
	}

	public updated() {
		if (typeof window.onscroll === "function") {
			// @ts-ignore
			window.onscroll();
		}
	}

	// public updated() {
	// 	if (this.scrollBlock) {
	// 		console.log("Scrolling is blocked for now");
	// 	} else if (this.lastScrollIndex >= this.conversation.length + 1) {
	// 		console.log("Conversations are the same");
	// 	} else {
	// 		this.scrollBlock = true;
	// 		this.lastScrollIndex++;
	// 		this.scrollTo(this.lastScrollIndex);
	// 		setTimeout(() => {
	// 			this.scrollBlock = false;
	// 			this.lastScrollIndex = this.conversation.length;
	// 		}, 1000);
	// 	}
	// }

	public close() {
		console.log("closing");
		// @ts-ignore
		Spin.show();
		this.conversation = [];
		this.sessionId = "";
		window.close();
		setTimeout(() => {
			// @ts-ignore
			Spin.hide();
			// @ts-ignore
			Modal.info({
				title: this._("Closing"),
				content:
					this._("This windows may not be closed automatically, please close your browser or app."),
				onOk: () => {
					window.location.reload();
				}
			});
		}, 2000);
	}

	public askLanguage() {
		console.log("Opening language window...");
		this.currentLanguage = "";
	}

	public async ask(text: string, suggestion?: any, userInput?: boolean, confirmSend: boolean = false) {
		// @ts-ignore
		if (userInput === true && text.trim() === "") {
			console.log("Ignoring empty user input");
			return false;
		}
		if (this.currentLanguage === "") {
			console.log("No lang selected, ignoring ask");
			return false;
		}

		if (userInput && this.censors.length > 0 && !confirmSend) {
			const action = await this.censorAction(text);

			if (action === "block") {
				// @ts-ignore
				Modal.warning({
					title: this._("Question not sent"),
					content: this._("Your question contains highly sensitive information and has not been sent.")
				});

				return false;
			} else if (action === "alert") {
				// @ts-ignore
				Modal.confirm({
					title: this._("Are you sure?"),
					// tslint:disable-next-line:max-line-length
					content: this._("Your question contains sensitive information, do you still want to send it?"),
					closable: false,
					cancelText: this._("Cancel"),
					okText: this._("Yes, send it"),
					onCancel: () => {
						return false;
					},
					onOk: () => {
						this.ask(this.question, suggestion, userInput, true);
					}
				});

				return false;
			}
		}

		const self = this;
		if (text !== "") {
			this.conversation.push({ type: "user", message: { input: text } });
		}
		setTimeout(() => {
			self.typing = true;

			if (this.conversation.length > 1) {
				this.scrollDown();
				setTimeout(() => {
					this.scrollDown();
				}, 200);
			}

			this.question = "";
			const payload: any = {
				session_id: this.sessionId,
				language: this.currentLanguage,
				input: {
					message_type: "text",
					text
				},
				context: {
					site: self.config.id,
					location: self.location.replace("-dev", ""),
					language: this.currentLanguage,
					// @ts-ignore
					locationName: config.locationNames[self.location.replace("-dev", "")]
				}
			};

			if (text === "") {
				payload.init = true;
			}

			if (this.captchaToken !== "") {
				payload.captcha = this.captchaToken;
			}

			if (typeof suggestion === "object") {
				payload.input.suggestion_id = suggestion.value.input.suggestion_id;
				payload.input.message_type = undefined;
				payload.input.intents = suggestion.value.input.intents;
			}

			return fetch(`${this.API}/chat/message/${this.assistantId}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(payload)
			})
				.then(async res => {
					if (res.ok === false) {
						if (typeof res.json === "function") {
							let error = "";
							try {
								const jsonError = await res.json();
								error = jsonError.error;
							} catch (e) {
								console.error("Failed parsing error code", e);
							}
							if (error === "InvalidSession") {
								throw new Error(this._("Your session has timed out. Please reopen this window and try again."));
							} else if (error === "CaptchaRequired" || error === "CaptchaError" || error === "InvalidCaptcha") {
								this.captcha = true;
								this.captchaToken = "";
								if (suggestion === undefined) {
									this.question = text;
								}
								throw new Error("captcha");
							}
						} else {
							throw new Error(this._("Unable to communicate with the server. Please check your connection and try again."));
						}
					}
					return res;
				})
				.then(res => res.json())
				.then(async data => {
					if (typeof data.translations === "object") {
						this.translationCache = Object.assign({},
							this.translationCache,
							data.translations
						);
					}
					data.translations = undefined;
					console.log("Clearing session timeout");
					clearTimeout(this.sessionTimeout);
					this.sessionTimeout = setTimeout(() => {
						console.log("Timeout");
						this.timeoutAlert();
					}, 290000);

					if (typeof data.output === "object" && typeof data.output.generic === "object") {
						// Loop the data we got and push all the entries one by one with the correct types and doing pauses
						for (const message of data.output.generic) {
							switch (message.response_type) {
								case "pause":
									this.typing = message.typing;
									console.log("Pausing for", message.time);
									await this.sleep(message.time);
									this.scrollDown();
									break;
								default:
									console.log("pushing");
									if (message.response_type === "blocks") {
										message.blocks.forEach((block: { expanded: boolean; }) => block.expanded = false);
									}
									this.conversation.push({ type: message.response_type, message });
									break;
							}
						}
					} else {
						this.conversation.push({ type: "unknown", message: {} });
					}
					console.log({ data });
					// this.conversation.push(data);

				})
				.finally(() => {
					this.typing = false;
				})
				.catch(e => {
					if (e.message !== "captcha") {
						// @ts-ignore
						Modal.error({
							title: this._("Error"),
							content: this._("Error with chat.") + " " + e
						});
					}
				});
		}, 200);
	}

	public _(input: string) {
		let output = "";
		if (typeof this.translationCache[input] !== "undefined") {
			output = this.translationCache[input];
		} else {
			// @ts-ignore
			if (typeof window.untranslated !== "object") {
				// @ts-ignore
				window.untranslated = [];
			}
			// @ts-ignore
			window.untranslated.push(input);
			output = input;
		}
		for (const regex of this.regexHack) {
			output = output.replace(regex[0], regex[1]);
		}
		return output;
	}

	public clickChild(e: Event) {
		e.preventDefault();
		if (e === null) {
			return;
		}
		// @ts-ignore
		if (typeof e.target === "object" && e.target.children.length > 0) {
			// @ts-ignore
			e.target.children[0].click();
		}
	}

	public async sleep(time: number) {
		return new Promise(resolve => setTimeout(resolve, time));
	}

	public async setCaptcha(response: string) {
		fetch(`${this.API}/chat/challenge`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				response
			})
		}).then(res => res.json())
			.then(async data => {
				if (data.success) {
					this.captchaToken = data.jwt;
					this.captcha = false;
					if (this.conversation.length < 2) {
						this.ask("");
					}
				} else {
					throw this._("Server couldn't verify.");
				}
			})
			.catch(e => {
				// @ts-ignore
				Modal.error({
					title: this._("Error"),
					content: this._("Failed to verify security challenge. Please try again later...") + " " + e
				});
			});
	}

	public censorAction(text: string): Promise<string> {
		return new Promise(resolve => {
			this.censors.forEach((censor, i) => {
				if (censor.regex.test(text)) {
					if (censor.action === "block") {
						this.question = this.question.replace(censor.regex, "(censored)");
					}
					resolve(censor.action);
				}

				if (i === this.censors.length - 1) {
					resolve("clean");
				}
			});
		});
	}

	public showRefreshUI(e: any) {
		this.registration = e.detail;
		// @ts-ignore
		Notice.info({
			title: " ",
			render: (h: any) => {
				return [
					h("div",
						{
							class: "ivu-notice-title",
							style: "margin: -5px 0px 15px"
						},
						this._("Update available") as string
					),
					h(Button,
						{
							props: {
								type: "success",
								long: true,
								ghost: true
							},
							on: {
								click: this.refreshApp
							}
						},
						this._("Update now") as string
					)
				];
			}
		});
	}

	public showFeedbackUI() {
		this.feedbackUIVisible = true;

		let feedback: string;
		let rating = 0;
		let session = true;

		const self = this;

		// @ts-ignore
		Modal.confirm({
			closable: false,
			okText: this._("Send"),
			cancelText: this._("Close"),
			loading: true,
			render: (h: any) => {
				return [
					h("h3", this._("Leave your feedback")),
					h("p", this._("You can rate this conversation and leave feedback.")),
					h("strong", this._("Rate:") + " "),
					h(Rate, {
						props: {
							value: rating,
							clearable: true
						},
						on: {
							"on-change": (val: number) => {
								console.log(val);
								rating = val;
							}
						}
					}),
					h("br"),
					h("strong", this._("Text:") + " "),
					h(Input, {
						props: {
							type: "textarea",
							autofocus: true,
							maxlength: 1024,
							showWordLimit: true,
							autosize: { minRows: 3, maxRows: 15 },
							placeholder: this._("Your feedback."),
						},
						attrs: {
							style: "margin-bottom: 10px"
						},
						on: {
							input: (val: string) => feedback = val
						}
					}),
					h(Checkbox, {
						props: {
							value: session,
							size: "large"
						},
						on: {
							"on-change": (val: boolean) => session = val
						}
					}, " " + this._("Attach current conversation to the feedback."))
				];
			},
			onOk: async () => {
				// The send button will be disabled and showing a loading animation until remove is called.
				// So in case of an error, confirm a retry for sending the request.
				const payload: any = {
					feedback,
					rating,
					location: this.location
				};

				if (session) {
					payload.sessionId = this.sessionId;
				}
				try {
					await this.sendFeedback(payload);
					// Only close if the feedback was sent
					// @ts-ignore
					Modal.remove();
					self.feedbackUIVisible = false;
					// @ts-ignore
					iMessage.success({
						content: this._("Thank you for your feedback."),
						background: true,
						closable: true,
						duration: 15
					});
				} catch (e) {
					setTimeout(async () => {
						try {
							await this.sendFeedback(payload);
							// @ts-ignore
							Modal.remove();
							// @ts-ignore
							iMessage.success({
								content: this._("Thank you for your feedback."),
								background: true,
								closable: true,
								duration: 15
							});
						} catch (e) {
							// @ts-ignore
							Modal.error({
								title: this._("Error"),
								content: e
							});
						}
						self.feedbackUIVisible = false;
					}, 5000);
				}
			},
			onCancel() {
				// Closes automatically
				self.feedbackUIVisible = false;
			}
		});
	}

	public toggleDictation() {
		if (this.dictating) {
			this.dictation.stop();
			this.dictating = false;
		} else {
			this.dictation.start();
			this.dictating = true;
		}
	}

	public refreshApp() {
		if (!this.registration || !this.registration.waiting) {
			window.location.reload();
			return;
		}
		this.registration.waiting.postMessage("skipWaiting");
	}

	public scrollDown() {
		console.log("SCROLLING");
		window.scrollTo(window.scrollX, document.body.scrollHeight);
	}

	public mounted() {
		this.mountScroll();
	}

	private sendFeedback(payload: any) {
		const self = this;
		return fetch(`${this.API}/chat/feedback`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(payload)
		})
			.then(async res => {
				if (res.ok) {
					return res;
				} else {
					throw res.status;
				}
			})
			.then(res => res.json())
			.then(async data => {
				if (typeof data.success !== "string") {
					throw new Error("Data not saved");
				}
				return data;
			});
	}

	private mountScroll() {
		window.onscroll = () => {
			this.scrolledToBottom = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
				+ window.innerHeight >= (document.documentElement.offsetHeight - 90);
		};
	}

	// public scrollTo(index: number) {
	// 	console.log("Scrolling to index of", index);
	// 	try {
	// 		// @ts-ignore
	// 		console.log(this.$refs.message[index]);
	// 		// @ts-ignore
	// 		this.$refs.message[index].$el.scrollIntoView({ behaviour: "smooth" });
	// 	} catch (e) {
	// 		console.warn(e);
	// 		console.log(this.$refs.message);
	// 	}
	// }


	@Watch("currentLanguage")
	private onPropertyChange(value: string, oldValue: string) {
		if (this.currentLanguage !== "" && this.inited === true) {
			console.log(
				"changed language to",
				this.currentLanguage,
				"from",
				oldValue
			);
			this.conversation = [];
			this.translationCache = {};
			this.$router.replace({ query: { language: this.currentLanguage } });
			document.documentElement.lang = this.currentLanguage;
			this.init();
		}
	}

	private getLanguages() {
		return fetch(`${this.API}/chat/languages`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(res => res.json())
			.then(async data => {
				const languages: any = [];
				if (typeof this.config.languages.whitelist === "object") {
					data.filter((language: any) => {
						if (this.config.languages.whitelist.includes(language.code)) {
							languages.push(language);
						}
					});
					this.languages = languages;
				} else {
					this.languages = data;
				}
				if (typeof this.config.languages.top === "object") {
					const topLanguages: any = [];
					this.languages.filter((language: any) => {
						if (this.config.languages.top.includes(language.code)) {
							topLanguages.push(language);
						}
					});
					if (topLanguages.length > 0) {
						this.topLanguages = topLanguages;
					}
				}
			})
			.catch(e => {
				// @ts-ignore
				Modal.error({
					title: this._("Error"),
					content: this._("Error loading languages. Please check your connection and try again.") + " " + e
				});
			});
	}

	private timeoutAlert() {
		if (!this.feedbackUIVisible) {
			// @ts-ignore
			Modal.warning({
				title: this._("Session Timed Out"),
				content: this._("Your session has timed out. Please refresh this page to start a new chat."),
				closable: false,
				okText: this._("Refresh Chat"),
				onOk: () => {
					window.location.reload();
				}
			});
		} else {
			console.log("Timeout warning ingores as feedback is open.");
		}
	}
}
